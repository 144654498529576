import React, {useContext, useEffect, useState} from "react";
import registerActs from "./register-acts.json";
import UserSection from "../../UI/Sections/UserSection";
import Btn from "../../UI/Buttons/Btn";
import {Link, useLocation} from "react-router-dom";
import Warnings from "../../UI/Sections/Warnings";
import UserContext from "../../../store/user/user-context";
import {sendSequentialRequests} from "../../SendRequests/sendSequentialRequests";
import {ErrorOccurred} from "../../UI/Sections/ErrorOccurred";
import axios from "axios";
import UploadAct from "./UploadAct";
import {Box, Button, Typography} from "@mui/material";

export default function UploadRegistrationDocs() {

    const registerActsNames = registerActs;

    const userSettings = useContext(UserContext);
    const contractData = new URLSearchParams(useLocation().search);
    const subcompany_id = +contractData.get('subcompany');
    const tender_id = +contractData.get('tender');

    const [answers, setAnswers] = useState(null);
    const [currentContract, setCurrentContract] = useState(null);
    const [currentActs, setCurrentActs] = useState(null);
    const company_id = currentContract?.tender.project.company.id;
    const project_id = currentContract?.tender.project.id;

    const [actsNames, setActsNames] = useState([]);

    const deleteActHandler = async (act_id, name) => {
        const deletedAct = await axios.delete(
            `${userSettings.api}/subcompanies/${subcompany_id}/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/register-acts/${act_id}`,
            {headers: userSettings.headers.postX}
        )
        console.log(deletedAct)
        setActsNames(prev => [...prev, name])

        setCurrentActs(currentActs.filter(act => act.id !== act_id))
    }


    const signActsHandler = async (data) => {
        try {
            const key = Object.keys(data)[0];
            console.log("name, ", key);
            console.log("file, ", data[key][0]);

            const formData = new FormData();
            formData.append('name', key);
            formData.append('file', data[key][0]);
            console.log(...formData);

            const uploadAct = await axios.post(
                `${userSettings.api}/subcompanies/${subcompany_id}/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/register-acts/`,
                formData,
                {headers: userSettings.headers.postForm}
            )
            console.log("upload act", uploadAct)

            if (uploadAct.data) {

                const updateActs = !currentActs ? [uploadAct.data] : [...currentActs, uploadAct.data];
                // console.log(`\n updateDocuments`, updateDocuments);
                setCurrentActs(updateActs);

                const updateNames = actsNames.filter(name => name !== uploadAct.data.name);
                setActsNames(updateNames);
                setAnswers(<Warnings correct>Документ добавлен</Warnings>);
            }
        } catch (error) {

            const responseError = ErrorOccurred(
                error.response,
                "Ошибка подписания актов",
                "SignActsHandler"
            );
            setAnswers(responseError.jsx);
        }
    }

    useEffect(() => {

        const getInfo = async () => {

            setAnswers(null);

            try {

                const wonTenderLots = await axios.get(
                    `${userSettings.api}/subcompanies/${subcompany_id}/won-tender-lots`,
                    userSettings.headers.get
                )
                const updateCurrentContract = wonTenderLots.data.find(l => l.tender.id === tender_id);
                // console.log(`\n updateCurrentContract`, updateCurrentContract);
                setCurrentContract(updateCurrentContract);
            } catch (error) {

                const responseError = ErrorOccurred(
                    error.response,
                    "Ошибка получения актов",
                    "getInfo"
                );
                setAnswers(responseError.jsx);
            }
        }

        getInfo();

    }, [subcompany_id, userSettings, currentActs]);
    useEffect(() => {

        const getRegisterActs = async () => {

            try {

                const response = await axios.get(
                    `${userSettings.api}/subcompanies/${subcompany_id}/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/register-acts/`,
                    userSettings.headers.get
                );
                // console.log(`\n response`, response.data);
                if (response.data.length > 0) {

                    const unUploadedNames = registerActsNames.filter(name => !response.data.map(d => d.name).includes(name));
                    // console.log(`\n unUploadedNames`, unUploadedNames);
                    setActsNames(unUploadedNames);
                    setCurrentActs(response.data);
                } else {
                    setActsNames(registerActsNames);
                    setCurrentActs(null);
                }
            } catch (error) {

                const responseError = ErrorOccurred(
                    error.response,
                    "Ошибка получения актов при регистрации",
                    "getRegisterActs"
                );
                setAnswers(responseError.jsx);
            }
        }

        if (company_id && project_id) getRegisterActs();
    }, [company_id, project_id, userSettings, subcompany_id, tender_id, registerActsNames]);

    if (currentActs) console.log(`\n currentActs`, currentActs);

    return (
        <UserSection>
            <h1>Загрузить сформированные акты</h1>
            <Btn method={`back`} color={`button`}>
                <Link to={'/work/'}>Назад</Link>
            </Btn>
            {answers && answers}
            <div className={`flex gap-2 flex-wrap`}>
                {actsNames.map((name) => {
                    return (
                        <UploadAct
                            signActsHandler={signActsHandler}
                            name={name}
                            key={name}
                        />
                    );
                })}
                {currentActs?.map(act =>
                    <Box key={act.id} className="flex flex-col pb-2 border border-black rounded gap-4">
                        <Typography className="text-white bg-black px-2">{act.name}</Typography>
                        <Box className="flex justify-center px-4">
                            <Button className="w-fit" variant="contained">
                                <Link to={`${userSettings.fileServer}${act.file}`} target="_blank">
                                    Скачать
                                </Link>
                            </Button>
                            {/*<Button onClick={() => deleteActHandler(act.id, act.name)} color="error" variant="contained">Удалить акт</Button>*/}
                        </Box>
                    </Box>
                )
                }
            </div>
        </UserSection>
    );
}