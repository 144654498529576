import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../../store/user/user-context";
import ViewingDrawings from "../../UI/Plan/ViewingDrawings";
import axios from "axios";
import Warnings from "../../UI/Sections/Warnings";
import Btn from "../../UI/Buttons/Btn";
import {Link} from "react-router-dom";

export default function ViewOrder() {
    const userSettings = useContext(UserContext);
    const params = Object.fromEntries(new URLSearchParams(window.location.search));
    const company_id = params.company;
    const project_id = params.project;
    const tender_id = params.tender;
    const building_id = params.building;
    const section_id = params.section;
    const floor_id = params.floor;
    const order_id = params.order;
    const subcompany_id = localStorage.company;
    // console.log(params);

    const [range, setRange] = useState(null);
    const [order, setOrder] = useState(null);
    const [answers, setAnswers] = useState(null);

    useEffect(() => {
        const getSection = async () => {
            try {

                const sectionList = await axios.get(
                    `${userSettings.api}/plans-configurator/companies/${company_id}/projects/${project_id}/building/${building_id}/sections/`,
                    userSettings.headers.get
                );
                const updateRange = sectionList.data.find(s => s.id === +section_id).ranges.find(r => r.floors.includes(+floor_id));
                // console.log(`\n updateRange`, updateRange);
                setRange(updateRange);

                const getOrders = await axios.get(
                    `${userSettings.api}/company/${company_id}/projects/${project_id}/tenders/${tender_id}/buildings/${building_id}/sections/${section_id}/orders/`,
                    userSettings.headers.get
                );

                // console.log(`\n getOrders`, getOrders.data.data.find(o => o.id === +order_id));
                setOrder(getOrders.data.data.find(o => o.id === order_id));
            } catch (error) {
                console.log(`\n error`, error.response);
            }
        };

        getSection();
    }, [building_id, company_id, project_id, section_id, tender_id, userSettings, floor_id, order_id]);

    const signOrderHandler = async (data) => {

        console.log(`\n signOrderHandler`, data);

        try {

            const form = new FormData();
            form.append('order_file', data.order_file[0]);

            const sendOrder = await axios.post(
                `${userSettings.api}/company/${company_id}/projects/${project_id}/tenders/${tender_id}/buildings/${building_id}/sections/${section_id}/orders/${order_id}/sign-by-subcontractor/subcompanies/${subcompany_id}/`,
                form,
                {headers: userSettings.headers.postForm}
            );

            // console.log(`\n sendOrder`, sendOrder);
            if (sendOrder) window.location.replace('/work/subcompany/welcome/');
        }
        catch (error) {

            setAnswers(<Warnings>
                Ошибка предписания - {error.response.data.errors ?
                error.response.data.errors.map((e,id) => <p key={id}>{e.detail}</p>)
                :
                error.response.status
            }
            </Warnings>)
            console.log(`\n createOrderHandler error`, error.response);
        }
    }
    const callToOrderHandler = async () => {

        console.log(`\n callToOrderHandler`);

        try {

            const callToOrder = await axios.post(
                `${userSettings.api}/company/${company_id}/projects/${project_id}/tenders/${tender_id}/buildings/${building_id}/sections/${section_id}/orders/${order_id}/call-build-control/subcompanies/${subcompany_id}/`,
                '',
                {headers: userSettings.headers.postX}
            );

            // console.log(`\n callToOrder`, callToOrder);
            if (callToOrder) window.location.replace('/work/subcompany/welcome/');
        }
        catch (error) {

            setAnswers(<Warnings>
                Ошибка предписания - {error.response.data.errors ?
                error.response.data.errors.map((e,id) => <p key={id}>{e.detail}</p>)
                :
                error.response.status
            }
            </Warnings>)
            console.log(`\n createOrderHandler error`, error.response);
        }
    }

    console.log(`\n range`, range);
    console.log(order)

    if (range) return (
        <React.Fragment>
            {answers && <div className={`w-max absolute bottom-1 right-1 z-[9999999]`}>{answers}</div>}
            <div className={`absolute left-28 z-[9999999]`}>
                <Btn method={'back'} color={`button`}>
                    <Link to={`/work/subcompany/welcome`}>Назад</Link>
                </Btn>
            </div>
            <ViewingDrawings
                imageUrl={`${userSettings.fileServer}${range.plan}`}
                subcompanyOrder={order}
                fileServer={userSettings.fileServer}
                signOrder={signOrderHandler}
                callToOrder={callToOrderHandler}
            />
        </React.Fragment>
    );
}