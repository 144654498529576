import React from 'react';
import {Document, Font, Page, StyleSheet, Text, View} from "@react-pdf/renderer";
import {getCurrentDate} from "../../Work/Subcompany/KS-2/helpers/getCurrentDate";

Font.register({
    family: "Roboto",
    fonts: [
        {src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf"},
        {
            src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf",
            fontWeight: 600
        },
    ]
});

const styles = StyleSheet.create({
    body: {
        fontFamily: "Roboto",
        fontSize: 6,
        padding: 5
    },
    page: {
        display: "flex",
        flexDirection: "column",
    },
    title: {
        fontFamily: 'Roboto',
        fontSize: 12,
        fontWeight: 600
    },
    container: {
        display: "flex",
        flexDirection: "row",
        paddingLeft: 5
    },
    leftColumn: {
        flex: 2,
    },
    rightColumn: {
        flex: 1
    },
    block: {
        margin: "10px 0",
    },
    bigPadding: {
        padding: 10
    },
    boldBorder: {
        border: '1px solid black',
    },
    row: {
        display: "flex",
        flexDirection: "row",
    },
    rowHeight: {
        height: 30
    },
    smallHeader: {
        border: "0.5px solid black",
        margin: "-0.5px -0.5px 0 0"
    },
    textCenter: {
        textAlign: "center",
    },
    center: {
        alignItems: "center",
        justifyContent: "center"
    },
    end: {
        alignItems: "flex-end",
    },
    horizontalBreak: {
        width: "100%",
        borderBottom: "1px solid black",
    },
    underline: {
        borderBottom: "1px solid black",
    },
});

const KS2 = (props) => {
    const {
        company,
        subcompany,
        object_name,
        workNames,
        workDates,
        logsForTable,
        estimateElements,
        currentTender,
        summary,
        signatories
    } = props.fetchedInfo;
    const customer = props.fetchedInfo.personsList.find(p => p.person === 1);

    const currentDate = getCurrentDate();
    const tender_cost = currentTender.criteria[0].value;


    const writeIfExists = (...all) => {
        return all.filter(text => text?.length > 0).join(", ");
    }

    return (
        <Document
            title='КС-2'
        >
            <Page style={styles.body}>
                <View style={[styles.block, styles.end, {paddingRight: 5}]}>
                    <Text>Унифицированная форма № КС-2</Text>
                    <Text>Утверждена Постановлением Госкомстата России</Text>
                    <Text>от 11.11.99 № 100</Text>
                </View>

                <View style={styles.container}>
                    <View style={{flex: 1}}>
                        <View style={[styles.end]}>
                            <View style={[styles.center, styles.smallHeader, {width: 90, height: 14, borderBottom: 0}]}>
                                <Text>
                                    Код
                                </Text>
                            </View>
                        </View>

                        <View style={[styles.end]}>
                            <View style={styles.row}>
                                <View style={{alignItems: "flex-end", justifyContent: "flex-end", marginRight: 1}}>
                                    <Text>Форма по ОКУД</Text>
                                </View>
                                <View style={[styles.center, styles.smallHeader, {
                                    width: 90,
                                    borderBottom: 0,
                                }]}>
                                    <Text>
                                        0322005
                                    </Text>
                                </View>
                            </View>
                        </View>

                        <View style={[styles.row]}>
                            <Text>Инвестор</Text>
                            <View style={{marginHorizontal: 5, flex: 1}}>
                                <View style={styles.textCenter}>
                                    <Text
                                        style={styles.horizontalBreak}>{writeIfExists(company.name, company.legal_address)}</Text>
                                </View>
                            </View>

                            <View style={styles.row}>
                                <View style={{alignItems: "flex-end", justifyContent: "flex-end", marginRight: 1}}>
                                    <Text>по ОКПО</Text>
                                </View>
                                <View style={[styles.center, styles.smallHeader, {
                                    width: 90,
                                    borderBottom: 0,
                                    height: "100%"
                                }]}>
                                    <Text>
                                        {""}
                                    </Text>
                                </View>
                            </View>
                        </View>

                        <View style={[styles.end]}>
                            <View style={[styles.row, {width: 400, justifyContent: "space-between"}]}>
                                <View>
                                    <Text>(организация, адрес, телефон, факс)</Text>
                                </View>

                                <View style={styles.end}>
                                    <View style={[styles.smallHeader, {width: 90, borderBottom: 0}]}>
                                        <Text>{" "}</Text>
                                    </View>
                                </View>
                            </View>
                        </View>


                        <View style={[styles.row]}>
                            <Text>Заказчик (Генподрядчик)</Text>
                            <View style={{marginHorizontal: 5, flex: 1}}>
                                <View style={styles.textCenter}>
                                    <Text
                                        style={styles.horizontalBreak}>{writeIfExists(customer.name, customer.legal_address)}</Text>
                                </View>
                            </View>

                            <View style={styles.row}>
                                <View style={{alignItems: "flex-end", justifyContent: "flex-end", marginRight: 1}}>
                                    <Text>по ОКПО</Text>
                                </View>
                                <View style={[styles.center, styles.smallHeader, {
                                    width: 90,
                                    borderBottom: 0,
                                    borderTop: 0,
                                }]}>
                                    <Text>
                                        {" "}
                                    </Text>
                                </View>
                            </View>
                        </View>

                        <View style={[styles.end]}>
                            <View style={[styles.row, {width: 400, justifyContent: "space-between"}]}>
                                <View>
                                    <Text>(организация, адрес, телефон, факс)</Text>
                                </View>

                                <View style={styles.end}>
                                    <View style={[styles.smallHeader, {width: 90, borderBottom: 0}]}>
                                        <Text>{" "}</Text>
                                    </View>
                                </View>
                            </View>
                        </View>

                        <View style={[styles.row]}>
                            <Text>Подрядчик (Субподрядчик)</Text>
                            <View style={{marginHorizontal: 5, flex: 1}}>
                                <View style={styles.textCenter}>
                                    <Text
                                        style={styles.horizontalBreak}>{writeIfExists(subcompany.name, subcompany.legal_address)}</Text>
                                </View>
                            </View>

                            <View style={styles.row}>
                                <View style={{alignItems: "flex-end", justifyContent: "flex-end", marginRight: 1}}>
                                    <Text>по ОКПО</Text>
                                </View>
                                <View style={[styles.center, styles.smallHeader, {
                                    width: 90,
                                    borderBottom: 0,
                                    borderTop: 0,
                                }]}>
                                    <Text>
                                        {" "}
                                    </Text>
                                </View>
                            </View>
                        </View>

                        <View style={[styles.end]}>
                            <View style={[styles.row, {width: 400, justifyContent: "space-between"}]}>
                                <View>
                                    <Text>(организация, адрес, телефон, факс)</Text>
                                </View>

                                <View style={styles.end}>
                                    <View style={[styles.smallHeader, {width: 90, borderBottom: 0}]}>
                                        <Text>{" "}</Text>
                                    </View>
                                </View>
                            </View>
                        </View>

                        <View style={[styles.row]}>
                            <Text>Стройка</Text>
                            <View style={{marginLeft: 5, flex: 1}}>
                                <View style={styles.textCenter}>
                                    <Text style={styles.horizontalBreak}>{" "}</Text>
                                </View>
                            </View>

                            <View style={styles.row}>
                                <View style={[styles.center, styles.smallHeader, {
                                    width: 90,
                                    borderTop: 0,
                                }]}>
                                    <Text>
                                        {" "}
                                    </Text>
                                </View>
                            </View>
                        </View>

                        <View style={[styles.end]}>
                            <View style={[styles.row, {width: 350, justifyContent: "space-between"}]}>
                                <View>
                                    <Text>(наименование, адрес)</Text>
                                </View>

                                <View style={styles.end}>
                                    <View style={[styles.smallHeader, {width: 90, borderBottom: 0}]}>
                                        <Text>{" "}</Text>
                                    </View>
                                </View>
                            </View>
                        </View>

                        <View style={[styles.row]}>
                            <Text>Объект</Text>
                            <View style={{marginLeft: 5, flex: 1}}>
                                <View style={styles.textCenter}>
                                    <Text style={styles.horizontalBreak}>{object_name.object_name}</Text>
                                </View>
                            </View>

                            <View style={styles.row}>
                                <View style={[styles.center, styles.smallHeader, {
                                    width: 90,
                                    borderTop: 0,
                                }]}>
                                    <Text>
                                        {" "}
                                    </Text>
                                </View>
                            </View>
                        </View>

                        <View style={[styles.end]}>
                            <View style={[styles.row, {width: 350, justifyContent: "space-between"}]}>
                                <View>
                                    <Text>(наименование)</Text>
                                </View>

                                <View style={styles.row}>
                                    <Text style={{marginRight: 1}}>Вид деятельности по ОКДП</Text>
                                    <View style={styles.end}>
                                        <View style={[styles.smallHeader, {width: 90, borderTop: 0}]}>
                                            <Text>{" "}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>

                        <View style={[styles.end]}>
                            <View style={[styles.row]}>
                                <Text style={{marginRight: 1}}>Договор подряда (контракт)</Text>

                                <View style={[styles.end, styles.row]}>
                                    <View
                                        style={[styles.smallHeader, {width: 30, textAlign: "right", paddingRight: 1}]}>
                                        <Text>номер</Text>
                                    </View>
                                    <View style={[styles.smallHeader, {width: 90, borderTop: 0}]}>
                                        <Text>{" "}</Text>
                                    </View>
                                </View>
                            </View>
                        </View>

                        <View style={[styles.end]}>
                            <View style={[styles.end, styles.row]}>
                                <View style={[styles.smallHeader, {width: 30, textAlign: "right", paddingRight: 1}]}>
                                    <Text>дата</Text>
                                </View>
                                <View style={[styles.row]}>
                                    <View style={[styles.center, styles.smallHeader, {width: 30, borderLeft: 0}]}>
                                        <Text>{" "}</Text>
                                    </View>
                                    <View style={[styles.center, styles.smallHeader, {width: 30}]}>
                                        <Text>{" "}</Text>
                                    </View>
                                    <View style={[styles.center, styles.smallHeader, {width: 31}]}>
                                        <Text>{" "}</Text>
                                    </View>
                                </View>
                            </View>
                        </View>

                        <View style={[styles.end]}>
                            <View style={[styles.row]}>
                                <Text style={{marginRight: 1}}>Вид операции</Text>
                                <View style={styles.end}>
                                    <View style={[styles.smallHeader, {width: 90, borderTop: 0}]}>
                                        <Text>{" "}</Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>

                <View style={[styles.row, {marginLeft: 250, marginTop: 10}]}>
                    <View style={[styles.boldBorder, {width: 125, height: 40}]}>
                        <View style={styles.row}>
                            <View style={[
                                styles.smallHeader,
                                styles.center,
                                {width: 61, height: 20, borderLeft: 0}
                            ]}>
                                <Text>
                                    Номер документа
                                </Text>
                            </View>

                            <View style={[
                                styles.smallHeader,
                                styles.center,
                                {width: 63, height: 20}
                            ]}>
                                <Text>
                                    Дата составления
                                </Text>
                            </View>


                        </View>

                        <View style={styles.row}>
                            <View style={[
                                styles.smallHeader,
                                styles.center,
                                {width: 61, height: 20, borderLeft: 0}
                            ]}>
                                <Text></Text>
                            </View>

                            <View style={[
                                styles.smallHeader,
                                styles.center,
                                {width: 63, height: 20}
                            ]}>
                                <Text>{currentDate}</Text>
                            </View>
                        </View>
                    </View>

                    <View style={[styles.boldBorder, {width: 125, height: 40, marginLeft: 10}]}>
                        <View style={[styles.center, {height: 14}]}>
                            <Text>Отчетный период</Text>
                        </View>
                        <View style={styles.row}>
                            <View style={[
                                styles.smallHeader,
                                styles.center,
                                {width: 61, height: 10, borderLeft: 0}
                            ]}>
                                <Text>
                                    с
                                </Text>
                            </View>

                            <View style={[
                                styles.smallHeader,
                                styles.center,
                                {width: 63, height: 10}
                            ]}>
                                <Text>
                                    по
                                </Text>
                            </View>
                        </View>

                        <View style={styles.row}>
                            <View style={[
                                styles.smallHeader,
                                styles.center,
                                {width: 61, height: 16, borderLeft: 0}
                            ]}>
                                <Text>{workDates.minDate}</Text>
                            </View>

                            <View style={[
                                styles.smallHeader,
                                styles.center,
                                {width: 63, height: 16}
                            ]}>
                                <Text>{workDates.maxDate}</Text>
                            </View>
                        </View>

                    </View>
                </View>

                <View style={[styles.textCenter, styles.title, {marginTop: 5}]}>
                    <Text>АКТ</Text>
                    <Text>О ПРИЕМКЕ ВЫПОЛНЕННЫХ РАБОТ</Text>
                </View>

                <View style={[styles.row, styles.rowHeight, {marginTop: 10, paddingLeft: 5, paddingRight: 80}]}>
                    <Text>Сметная (договорная) стоимость в соответствии с договором подряда (субподряда)</Text>
                    <View style={{marginLeft: 15, flex: 1, fontWeight: 600}}>
                        <Text style={styles.horizontalBreak}>{" "}</Text>
                    </View>
                    <Text> руб.</Text>
                </View>

                <View>
                    <View style={[styles.row, styles.smallHeader, {height: 30}]}>
                        <View style={{width: 69.5, textAlign: 'center'}}>
                            <Text>Номер</Text>
                            <View style={styles.row}>
                                <View
                                    style={[styles.center, styles.smallHeader, {
                                        width: 30,
                                        height: 22,
                                        borderLeft: 0,
                                        borderBottom: 0
                                    }]}>
                                    <Text>по порядку</Text>
                                </View>
                                <View style={[styles.center, styles.smallHeader, {
                                    width: 40,
                                    height: 22,
                                    borderRight: 0,
                                    borderBottom: 0
                                }]}>
                                    <Text>позиции по смете</Text>
                                </View>
                            </View>
                        </View>

                        <View style={[styles.center, styles.smallHeader, {width: 189.75, borderBottom: 0}]}>
                            <Text>Наименование работ</Text>
                        </View>

                        <View style={[styles.center, styles.smallHeader, {width: 67.5, borderBottom: 0}]}>
                            <Text>Номер единичной расценки</Text>
                        </View>

                        <View style={[styles.center, styles.smallHeader, {width: 67, borderBottom: 0}]}>
                            <Text>Единица измерения</Text>
                        </View>

                        <View style={[styles.smallHeader, styles.textCenter, {width: 210, borderBottom: 0}]}>
                            <Text>Выполнено работ</Text>
                            <View style={[styles.row]}>
                                <View style={[styles.center, styles.smallHeader, {
                                    width: 70,
                                    height: 22,
                                    borderLeft: 0,
                                    borderBottom: 0
                                }]}>
                                    <Text>количество</Text>
                                </View>

                                <View style={[styles.center, styles.smallHeader, {
                                    width: 70,
                                    height: 22,
                                    borderBottom: 0
                                }]}>
                                    <Text>цена за единицу, руб.</Text>
                                </View>

                                <View style={[styles.center, styles.smallHeader, {
                                    width: 70,
                                    height: 22,
                                    borderBottom: 0
                                }]}>
                                    <Text>стоимость, руб.</Text>
                                </View>
                            </View>
                        </View>
                    </View>

                    <View style={[styles.row, styles.smallHeader]}>
                        <View
                            style={[styles.center, styles.smallHeader, {
                                width: 30,
                                height: 12,
                                borderBottom: 0,
                                borderLeft: 0
                            }]}>
                            <Text>1</Text>
                        </View>

                        <View style={[styles.center, styles.smallHeader, {width: 40, borderBottom: 0}]}>
                            <Text>2</Text>
                        </View>

                        <View
                            style={[styles.center, styles.smallHeader, {
                                width: 189,
                                borderLeft: 0,
                                borderBottom: 0,
                            }]}>
                            <Text>3</Text>
                        </View>

                        <View style={[styles.center, styles.smallHeader, {width: 67, borderBottom: 0}]}>
                            <Text>4</Text>
                        </View>

                        <View style={[styles.center, styles.smallHeader, {width: 67, borderBottom: 0}]}>
                            <Text>5</Text>
                        </View>

                        <View style={[styles.center, styles.smallHeader, {width: 70, borderBottom: 0}]}>
                            <Text>6</Text>
                        </View>

                        <View style={[styles.center, styles.smallHeader, {width: 70, borderBottom: 0}]}>
                            <Text>7</Text>
                        </View>

                        <View style={[styles.center, styles.smallHeader, {width: 70, borderBottom: 0}]}>
                            <Text>8</Text>
                        </View>
                    </View>

                    {logsForTable.map((element, index) =>
                        <View key={"first_table_" + element.id}>
                            <View style={[styles.row, styles.smallHeader]}>
                                <View
                                    style={[styles.center, styles.smallHeader, {
                                        width: 30,
                                        borderLeft: 0,
                                        borderBottom: 0,
                                    }]}>
                                    <Text>{index + 1}</Text>
                                </View>

                                <View
                                    style={[styles.center, styles.smallHeader, {
                                        width: 40,
                                        borderLeft: 0,
                                        borderBottom: 0,
                                    }]}>
                                    <Text>{element.number}</Text>
                                </View>

                                <View
                                    style={[styles.center, styles.smallHeader, {
                                        width: 189.25,
                                        borderLeft: 0,
                                        borderBottom: 0,
                                        alignItems: "left",
                                        paddingHorizontal: 5
                                    }]}>
                                    <Text>{element.name_works}</Text>
                                </View>

                                <View style={[styles.center, styles.smallHeader, {width: 67, borderBottom: 0,}]}>
                                    <Text></Text>
                                </View>

                                <View style={[styles.center, styles.smallHeader, {width: 67, borderBottom: 0,}]}>
                                    <Text>{element.units}</Text>
                                </View>

                                <View style={[styles.center, styles.smallHeader, {width: 70, borderBottom: 0,}]}>
                                    <Text>{element.value}</Text>
                                </View>

                                <View style={[styles.center, styles.smallHeader, {width: 70, borderBottom: 0,}]}>
                                    <Text>{element.costByUnit}</Text>
                                </View>

                                <View style={[styles.center, styles.smallHeader, {width: 70, borderBottom: 0,}]}>
                                    <Text>{element.totalCost}</Text>
                                </View>
                            </View>
                        </View>
                    )}

                    <View style={[styles.end]}>
                        <View style={styles.row}>
                            <Text style={{marginRight: 5}}>Итого</Text>
                            <View style={[styles.smallHeader, styles.center, {width: 68.3, textAlign: "center"}]}>
                                <Text>{summary}</Text>
                            </View>
                        </View>
                    </View>
                </View>

                {/*<View>*/}
                {/*    <View style={[styles.row, styles.smallHeader, {height: 30}]}>*/}
                {/*        <View style={{width: 70, textAlign: 'center'}}>*/}
                {/*            <Text>Номер</Text>*/}
                {/*            <View style={styles.row}>*/}
                {/*                <View*/}
                {/*                    style={[styles.center, styles.smallHeader, {*/}
                {/*                        width: 30,*/}
                {/*                        height: 22,*/}
                {/*                        borderLeft: 0,*/}
                {/*                        borderBottom: 0*/}
                {/*                    }]}>*/}
                {/*                    <Text>по порядку</Text>*/}
                {/*                </View>*/}
                {/*                <View style={[styles.center, styles.smallHeader, {*/}
                {/*                    width: 40,*/}
                {/*                    height: 22,*/}
                {/*                    borderRight: 0,*/}
                {/*                    borderBottom: 0*/}
                {/*                }]}>*/}
                {/*                    <Text>позиции по смете</Text>*/}
                {/*                </View>*/}
                {/*            </View>*/}
                {/*        </View>*/}

                {/*        <View style={[styles.center, styles.smallHeader, {width: 190, borderBottom: 0}]}>*/}
                {/*            <Text>Наименование работ</Text>*/}
                {/*        </View>*/}

                {/*        <View style={[styles.center, styles.smallHeader, {width: 67, borderBottom: 0}]}>*/}
                {/*            <Text>Номер единичной расценки</Text>*/}
                {/*        </View>*/}

                {/*        <View style={[styles.center, styles.smallHeader, {width: 67, borderBottom: 0}]}>*/}
                {/*            <Text>Единица измерения</Text>*/}
                {/*        </View>*/}

                {/*        <View style={[styles.smallHeader, styles.textCenter, {width: 210, borderBottom: 0}]}>*/}
                {/*            <Text>Выполнено работ</Text>*/}
                {/*            <View style={[styles.row]}>*/}
                {/*                <View style={[styles.center, styles.smallHeader, {*/}
                {/*                    width: 70,*/}
                {/*                    height: 22,*/}
                {/*                    borderLeft: 0,*/}
                {/*                    borderBottom: 0*/}
                {/*                }]}>*/}
                {/*                    <Text>количество</Text>*/}
                {/*                </View>*/}

                {/*                <View style={[styles.center, styles.smallHeader, {*/}
                {/*                    width: 70,*/}
                {/*                    height: 22,*/}
                {/*                    borderBottom: 0*/}
                {/*                }]}>*/}
                {/*                    <Text>цена за единицу, руб.</Text>*/}
                {/*                </View>*/}

                {/*                <View style={[styles.center, styles.smallHeader, {*/}
                {/*                    width: 70,*/}
                {/*                    height: 22,*/}
                {/*                    borderBottom: 0*/}
                {/*                }]}>*/}
                {/*                    <Text>стоимость, руб.</Text>*/}
                {/*                </View>*/}
                {/*            </View>*/}
                {/*        </View>*/}
                {/*    </View>*/}

                {/*    <View style={[styles.row, styles.smallHeader]}>*/}
                {/*        <View style={{width: 70}}>*/}
                {/*            <View style={[styles.row]}>*/}
                {/*                <View*/}
                {/*                    style={[styles.center, styles.smallHeader, {*/}
                {/*                        width: 30,*/}
                {/*                        height: 12,*/}
                {/*                        borderBottom: 0,*/}
                {/*                        borderLeft: 0*/}
                {/*                    }]}>*/}
                {/*                    <Text>1</Text>*/}
                {/*                </View>*/}
                {/*                <View style={[styles.center, styles.smallHeader, {width: 40.25, borderBottom: 0}]}>*/}
                {/*                    <Text>2</Text>*/}
                {/*                </View>*/}
                {/*            </View>*/}
                {/*        </View>*/}

                {/*        <View*/}
                {/*            style={[styles.center, styles.smallHeader, {*/}
                {/*                width: 189.25,*/}
                {/*                borderLeft: 0,*/}
                {/*                borderBottom: 0,*/}
                {/*            }]}>*/}
                {/*            <Text>3</Text>*/}
                {/*        </View>*/}

                {/*        <View style={[styles.center, styles.smallHeader, {width: 67, borderBottom: 0,}]}>*/}
                {/*            <Text>4</Text>*/}
                {/*        </View>*/}

                {/*        <View style={[styles.center, styles.smallHeader, {width: 67, borderBottom: 0,}]}>*/}
                {/*            <Text>5</Text>*/}
                {/*        </View>*/}

                {/*        <View style={[styles.center, styles.smallHeader, {width: 70, borderBottom: 0,}]}>*/}
                {/*            <Text>6</Text>*/}
                {/*        </View>*/}

                {/*        <View style={[styles.center, styles.smallHeader, {width: 70, borderBottom: 0,}]}>*/}
                {/*            <Text>7</Text>*/}
                {/*        </View>*/}

                {/*        <View style={[styles.center, styles.smallHeader, {width: 70, borderBottom: 0,}]}>*/}
                {/*            <Text>8</Text>*/}
                {/*        </View>*/}
                {/*    </View>*/}

                {/*    {Array(20).fill(1).map((_, index) =>*/}
                {/*        <View key={"second_table_" + index}>*/}
                {/*            <View style={[styles.row, styles.smallHeader]}>*/}
                {/*                <View style={{width: 70}}>*/}
                {/*                    <View style={[styles.row]}>*/}
                {/*                        <View*/}
                {/*                            style={[styles.center, styles.smallHeader, {*/}
                {/*                                width: 30,*/}
                {/*                                height: 12,*/}
                {/*                                borderBottom: 0,*/}
                {/*                                borderLeft: 0*/}
                {/*                            }]}>*/}
                {/*                            <Text></Text>*/}
                {/*                        </View>*/}
                {/*                        <View style={[styles.center, styles.smallHeader, {*/}
                {/*                            width: 40.25,*/}
                {/*                            borderBottom: 0*/}
                {/*                        }]}>*/}
                {/*                            <Text></Text>*/}
                {/*                        </View>*/}
                {/*                    </View>*/}
                {/*                </View>*/}

                {/*                <View*/}
                {/*                    style={[styles.center, styles.smallHeader, {*/}
                {/*                        width: 189.25,*/}
                {/*                        borderLeft: 0,*/}
                {/*                        borderBottom: 0,*/}
                {/*                    }]}>*/}
                {/*                    <Text></Text>*/}
                {/*                </View>*/}

                {/*                <View style={[styles.center, styles.smallHeader, {width: 67, borderBottom: 0,}]}>*/}
                {/*                    <Text></Text>*/}
                {/*                </View>*/}

                {/*                <View style={[styles.center, styles.smallHeader, {width: 67, borderBottom: 0,}]}>*/}
                {/*                    <Text></Text>*/}
                {/*                </View>*/}

                {/*                <View style={[styles.center, styles.smallHeader, {width: 70, borderBottom: 0,}]}>*/}
                {/*                    <Text></Text>*/}
                {/*                </View>*/}

                {/*                <View style={[styles.center, styles.smallHeader, {width: 70, borderBottom: 0,}]}>*/}
                {/*                    <Text></Text>*/}
                {/*                </View>*/}

                {/*                <View style={[styles.center, styles.smallHeader, {width: 70, borderBottom: 0,}]}>*/}
                {/*                    <Text></Text>*/}
                {/*                </View>*/}
                {/*            </View>*/}
                {/*        </View>*/}
                {/*    )}*/}

                {/*    <View style={[styles.end]}>*/}
                {/*        <View style={styles.row}>*/}
                {/*            <Text style={{marginRight: 5}}>Итого</Text>*/}

                {/*            <View style={[styles.smallHeader, styles.center, {width: 68.4, textAlign: "center"}]}>*/}
                {/*                <Text>{" "}</Text>*/}
                {/*            </View>*/}
                {/*        </View>*/}
                {/*    </View>*/}

                {/*    <View style={[styles.end]}>*/}
                {/*        <View style={styles.row}>*/}
                {/*            <Text style={{marginRight: 5}}>Всего по акту</Text>*/}

                {/*            <View style={[styles.smallHeader, styles.center, {width: 68.2, textAlign: "center"}]}>*/}
                {/*                <Text>{" "}</Text>*/}
                {/*            </View>*/}
                {/*        </View>*/}

                {/*    </View>*/}
                {/*</View>*/}

                <View style={[styles.row, {marginLeft: 100, marginTop: 20, gap: 10}]}>
                    <View style={{width: 175}}>
                        <View style={styles.textCenter}>
                            <Text style={styles.horizontalBreak}>{signatories.company.position}</Text>
                        </View>

                        <View style={styles.textCenter}>
                            <Text>(должность)</Text>
                        </View>
                    </View>

                    <View style={{width: 80}}>
                        <View style={styles.textCenter}>
                            <Text style={styles.horizontalBreak}>{" "}</Text>
                        </View>

                        <View style={styles.textCenter}>
                            <Text>(подпись)</Text>
                        </View>
                    </View>

                    <View style={{flex: 1}}>
                        <View style={styles.textCenter}>
                            <Text style={styles.horizontalBreak}>{signatories.company.short_name}</Text>
                        </View>

                        <View style={styles.textCenter}>
                            <Text>(расшифровка подписи)</Text>
                        </View>
                    </View>

                </View>

                <Text style={{marginLeft: 100, marginTop: 10}}>М.П.</Text>

                <View style={[styles.row, {marginLeft: 100, marginTop: 20, gap: 10}]}>
                    <View style={{width: 175}}>
                        <View style={styles.textCenter}>
                            <Text style={styles.horizontalBreak}>{signatories.subcompany.position}</Text>
                        </View>

                        <View style={styles.textCenter}>
                            <Text>(должность)</Text>
                        </View>
                    </View>

                    <View style={{width: 80}}>
                        <View style={styles.textCenter}>
                            <Text style={styles.horizontalBreak}>{" "}</Text>
                        </View>

                        <View style={styles.textCenter}>
                            <Text>(подпись)</Text>
                        </View>
                    </View>

                    <View style={{flex: 1}}>
                        <View style={styles.textCenter}>
                            <Text style={styles.horizontalBreak}>{signatories.subcompany.short_name}</Text>
                        </View>

                        <View style={styles.textCenter}>
                            <Text>(расшифровка подписи)</Text>
                        </View>
                    </View>

                </View>

                <Text style={{marginLeft: 100, marginTop: 10}}>М.П.</Text>

                {/*<View style={[styles.block, styles.end, {paddingRight: 5, marginTop: 5}]}>*/}
                {/*    <Text>2-я страница формы № КС-2</Text>*/}
                {/*</View>*/}
            </Page>
        </Document>
    )
};

export default KS2;